<template>
  <div>
    <div class="actions flex jc-end mb-4">
      <a-button
        type="danger"
        class="mr-3"
        @click="batchDel"
        icon="delete"
        v-if="selectedRowKeys.length && parent.isTongChou"
        v-has="'production:chapter:button:delete'"
        >删除</a-button
      >
      <a-button
        type="primary"
        class="mr-3"
        @click="recycleBinVisible = true"
        icon="hdd"
        v-has="'production:chapter:button:recyclebin'"
        >回收站</a-button
      >
      <a-button v-if="tableEdit" class="mr-3" @click="listCancel" v-has="'production:chapter:button:edit'"
        >取消</a-button
      >
      <a-button
        class="mr-3"
        @click="updateChapters"
        :icon="tableEdit ? 'save' : 'edit'"
        :type="tableEdit ? '' : 'primary'"
        v-has="parent.isTongChou ? 'production:chapter:button:edit' : 'production:chapter:button:edit:isTongchou'"
        >{{ tableEdit ? '保存' : '编辑' }}</a-button
      >
      <a-button
        type="primary"
        @click="openAddForm"
        icon="plus"
        v-if="parent.isTongChou"
        v-has="'production:chapter:button:add'"
        >添加</a-button
      >
    </div>
    <a-table
      v-has="'production:chapter:page:view'"
      style="border-top:1px solid rgb(232,232,232)"
      ref="table"
      size="middle"
      rowKey="id"
      :columns="
        columns.filter(item => (isJf && (item.dataIndex == 'sequenceNo' || item.dataIndex == 'chapterName')) || !isJf)
      "
      :dataSource="dataSource"
      :customRow="clickRow"
      :pagination="ipagination"
      :scroll="{ x: 'max-content', y: 'calc(100vh - 410px)' }"
      :loading="loading"
      :rowSelection="isJf ? null : { selectedRowKeys: selectedRowKeys, onChange: onSelectChange, columnWidth: 80 }"
      @change="handleTableChange"
    >
      <template slot="chapterState" slot-scope="text, record">
        {{ record.chapterState }}
      </template>
      <template slot="calcUnits" slot-scope="text, record">
        <span v-if="!tableEdit">
          {{
            getCalcUnits(
              record.calcUnits.filter(c => {
                return calcUnits.findIndex(i => i.type == c.type) > -1&&(c.value&&c.value>0)
              })
            )
          }}
        </span>
        <div
          v-else-if="tableEdit && record.calcUnits"
          style="display:flex;justify-content: space-between;align-items: center;"
        >
          <div
            style="display:flex;align-items:center"
            v-for="(c, index) in record.calcUnits.filter(c => {
              return calcUnits.findIndex(i => i.type == c.type) > -1
            })"
            :key="index"
          >
            <a-input-number :min="0" v-model="c.value"></a-input-number
            ><span style="padding-left:5px;padding-right:5px">{{ getUnit(c.type) }}</span>
          </div>
          <div
            style="display:flex;align-items:center"
            v-for="(c, index) in calcUnits.filter(c => {
              return record.calcUnits.findIndex(i => i.type == c.type) == -1
            })"
            :key="index"
          >
            <a-input-number :min="0" v-model="c.value"></a-input-number
            ><span style="padding-left:5px;padding-right:5px">{{ getUnit(c.type) }}</span>
          </div>
        </div>
      </template>
      <span slot="sequenceNo" slot-scope="text, record">
        <span v-if="!tableEdit">
          {{ record.sequenceNo ? parseFloat((record.sequenceNo - 0).toFixed(1)) : '' }}
        </span>
        <a-input-number v-else :min="0" v-model="record.sequenceNo" />
      </span>
      <span slot="chapterName" slot-scope="text, record">
        <span v-if="!tableEdit">
          {{ record.chapterName||'' }}
        </span>
        <a-input v-else v-model="record.chapterName" />
      </span>
      <template slot="user" slot-scope="text, record">
        <div v-if="record.user">
          <img style="width:30px;height:30px;border-radius:50%;margin-right:10px;" :src="record.user.avatar" alt="" />
          {{ record.user ? record.user.userName : '' }}
        </div>
      </template>
      <template slot="coverImage" slot-scope="text, record">
        <img class="cover_image" :src="record.coverImage" v-if="record.coverImage" />
      </template>
    </a-table>
    <chapter-modal
      @afterSave="parentLoadData"
      :calcUnits="calcUnits"
      :isTongChou="parent.isTongChou"
      :open="drawerShow"
      :cur-row="curRow"
      :isForm="isNewForm"
      @closed="drawerClosed"
    ></chapter-modal>

    <chapter-recycle-bin-modal :productionId="id" :visible.sync="recycleBinVisible" @ok="modalFormOk" />
  </div>
</template>

<script>
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import ChapterModal from './chapter-modal'
import ChapterRecycleBinModal from '../modules/ChapterRecycleBinModal'
import { putAction, getAction } from '../../../api/manage'

export default {
  mixins: [JeecgListMixin],
  components: {
    ChapterModal,
    ChapterRecycleBinModal
  },
  props: {
    parent: {
      type: Object,
      default: () => {}
    },
    calcUnits: {
      type: Array,
      default: []
    },
    isJf: {
      type: Boolean,
      default: false
    },
    tab: {
      type: String,
      default: '1'
    },
    columns: {
      type: Array,
      default: [
        {
          title: '序号',
          dataIndex: 'sequenceNo',
          width: 80,
          scopedSlots: { customRender: 'sequenceNo' }
        },
        {
          title: '标题',
          dataIndex: 'chapterName',
          width: 80,
          scopedSlots: { customRender: 'chapterName' }
        },
        {
          title: '结算数量',
          width: 140,
          dataIndex: 'calcUnits',
          scopedSlots: { customRender: 'calcUnits' }
        },
        {
          title: '当前阶段',
          width: 80,
          dataIndex: 'stageName'
        },
        {
          title: '当前人员',
          width: 120,
          scopedSlots: { customRender: 'user' },
          dataIndex: 'user'
        },
        {
          title: '当前截止日期',
          width: 100,
          dataIndex: 'currDeadline'
        },
        {
          title: '章节状态',
          width: 100,
          dataIndex: 'chapterState',
          scopedSlots: { customRender: 'chapterState' }
        },
        {
          title: '预警情况',
          width: 80,
          dataIndex: 'warningState'
        },
        {
          title: '交稿日期',
          width: 130,
          dataIndex: 'deliveryDate'
        },
        {
          title: '文本量',
          width: 80,
          dataIndex: 'textAmount'
        },
        {
          title: '原文字数',
          width: 110,
          dataIndex: 'originTextAmount'
        },
        {
          title: '译文字数',
          width: 110,
          dataIndex: 'translationTextAmount'
        }
      ]
    }
  },
  watch: {
    tab(n) {
      if (n != '1') return
      console.log(this.url)
      this.id = this.$route.params.id
      this.loadData(1, this.id)
    }
  },
  data() {
    return {
      tableEdit: false,
      recycleBinVisible: false,
      id: this.$route.params.id,
      url: {
        list: `/production/list_chapter`,
        deleteBatch: `/production/delete_chapter_batch`
      },
      copyData: [],
      clickRow: (record, index) => ({
        on: {
          click: () => {
            if (!this.tableEdit) {
              this.openDetail(record)
            }
          }
        }
      })
    }
  },
  methods: {
    loadData(arg, id, callback) {
      if (!this.url.list) {
        this.$message.error('请设置url.list属性!')
        return
      }
      //加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.current = 1
      }
      var params = this.getQueryParams() //查询条件
      if (this.id) params.id = this.id
      if (id) params.id = id
      this.loading = true
      if (this.url.list.path) {
        params.current = params.pageNo
        params.size = params.pageSize
        this.domain && (params.domain = this.domain)
        getAction(this.url.list.path, params).then(res => {
          console.log('JeecgListMixin loadData getAction over', { res })
          this.tableEdit = false
          if (res.success) {
            this.selectedRowKeys = []
            this.selectionRows = []
            //update-begin---author:zhangyafei    Date:20201118  for：适配不分页的数据列表------------
            //update-end---author:zhangyafei    Date:20201118  for：适配不分页的数据列表------------

            // 字体预览需要往head里插入css样式
            if (this.isInserstCss) {
              batchStyleInsertHeader(this.dataSource)
            }
            this.$nextTick(() => {
              this.dataSource = res.data.records || res.data
              if (res.data.total) {
                this.ipagination.total = res.data.total - 0
              } else {
                this.ipagination.total = 0
              }
              this.$emit('loadData', this.dataSource)
            })
          }
          if (res.code === 510) {
            this.$message.warning(res.message)
          }
          this.loading = false
        })
      } else {
        params.current = params.pageNo
        params.size = params.pageSize
        getAction(this.url.list, params).then(res => {
          this.tableEdit = false
          console.log('JeecgListMixin loadData getAction over', { res })
          if (res.success) {
            this.selectedRowKeys = []
            this.selectionRows = []
            //update-end---author:zhangyafei    Date:20201118  for：适配不分页的数据列表------------

            // 字体预览需要往head里插入css样式
            if (this.isInserstCss) {
              batchStyleInsertHeader(res.data.records || res.data)
              setTimeout(() => {
                this.dataSource = res.data.records || res.data
                if (res.data.total) {
                  this.ipagination.total = res.data.total - 0
                } else {
                  this.ipagination.total = 0
                }
                this.$emit('loadData', this.dataSource)
              }, 0)
            } else {
              this.dataSource = res.data.records || res.data
              if (res.data.total) {
                this.ipagination.total = res.data.total - 0
              } else {
                this.ipagination.total = 0
              }
              this.$emit('loadData', this.dataSource)
            }
          }
          if (res.code === 510) {
            this.$message.warning(res.message)
          }
          this.loading = false
        })
      }
    },
    getUnit(type) {
      let unit = ''
      switch (type) {
        case 'EACH':
          unit = '个'
          break
        case 'CHAPTER':
          unit = '话'
          break
        case 'VOLUME':
          unit = '部'
          break
        case 'PAGE':
          unit = '页'
          break
        case 'WORD':
          unit = '字'
          break
        case 'THOUSAND':
          unit = '千字'
          break
        case 'GRID':
          unit = '格'
          break
        case 'PERCENT':
          unit = '%'
          break
      }
      return unit
    },
    listCancel() {
      this.tableEdit = false
      this.dataSource = this.copyData
    },
    async updateChapters() {
      if (!this.tableEdit) {
        this.copyData = JSON.parse(JSON.stringify(this.dataSource))
        this.tableEdit = !this.tableEdit
      } else {
        this.dataSource.forEach(record => {
          if (record.calcUnits && record.calcUnits.length) {
            this.calcUnits.forEach(i => {
              if (record.calcUnits.every(item => i.type != item.type)) {
                record.calcUnits.push(i)
              }
            })
          } else {
            record.calcUnits = this.calcUnits
          }
        })
        this.loading = true
        const res = await putAction('/production/edit_chapter/batch', this.dataSource)
        this.loading = false
        if (res.code == 200) {
          this.$message.success('保存成功')
          this.tableEdit = !this.tableEdit
          this.loadData()
        } else {
          this.$message.error(res.msg)
        }
      }
      this.$forceUpdate()
    },
    getCalcUnits(calcUnits) {
      let text = []
      calcUnits?.forEach(item => {
        if (item.value) {
          let unit = ''
          switch (item.type) {
            case 'EACH':
              unit = item.value + '个'
              break
            case 'CHAPTER':
              unit = item.value + '话'
              break
            case 'VOLUME':
              unit = item.value + '部'
              break
            case 'PAGE':
              unit = item.value + '页'
              break
            case 'WORD':
              unit = item.value + '字'
              break
            case 'THOUSAND':
              unit = item.value + '千字'
              break
            case 'GRID':
              unit = item.value + '格'
              break
            case 'PERCENT':
              unit = item.value + '%'
              break
          }
          text.push(unit)
        }
      })
      text = text.join('')
      return text
    },
    setUrl() {
      this.url.list = `/production/list_chapter?id=${this.$route.params.id}`
    },
    parentLoadData() {
      this.$emit('loadData')
    }
  },
  created() {
    // this.loadData(1, this.id)
    // console.log('-------章节------')
  }
}
</script>

<style lang="less" scoped>
.cover_image {
  height: 60px;
}
/deep/ .ant-input-number-handler-wrap{
  display: none !important;
}
</style>
