var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "actions flex jc-end mb-4" },
        [
          _vm.selectedRowKeys.length && _vm.parent.isTongChou
            ? _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "production:chapter:button:delete",
                      expression: "'production:chapter:button:delete'",
                    },
                  ],
                  staticClass: "mr-3",
                  attrs: { type: "danger", icon: "delete" },
                  on: { click: _vm.batchDel },
                },
                [_vm._v("删除")]
              )
            : _vm._e(),
          _c(
            "a-button",
            {
              directives: [
                {
                  name: "has",
                  rawName: "v-has",
                  value: "production:chapter:button:recyclebin",
                  expression: "'production:chapter:button:recyclebin'",
                },
              ],
              staticClass: "mr-3",
              attrs: { type: "primary", icon: "hdd" },
              on: {
                click: function ($event) {
                  _vm.recycleBinVisible = true
                },
              },
            },
            [_vm._v("回收站")]
          ),
          _vm.tableEdit
            ? _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "production:chapter:button:edit",
                      expression: "'production:chapter:button:edit'",
                    },
                  ],
                  staticClass: "mr-3",
                  on: { click: _vm.listCancel },
                },
                [_vm._v("取消")]
              )
            : _vm._e(),
          _c(
            "a-button",
            {
              directives: [
                {
                  name: "has",
                  rawName: "v-has",
                  value: _vm.parent.isTongChou
                    ? "production:chapter:button:edit"
                    : "production:chapter:button:edit:isTongchou",
                  expression:
                    "parent.isTongChou ? 'production:chapter:button:edit' : 'production:chapter:button:edit:isTongchou'",
                },
              ],
              staticClass: "mr-3",
              attrs: {
                icon: _vm.tableEdit ? "save" : "edit",
                type: _vm.tableEdit ? "" : "primary",
              },
              on: { click: _vm.updateChapters },
            },
            [_vm._v(_vm._s(_vm.tableEdit ? "保存" : "编辑"))]
          ),
          _vm.parent.isTongChou
            ? _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "production:chapter:button:add",
                      expression: "'production:chapter:button:add'",
                    },
                  ],
                  attrs: { type: "primary", icon: "plus" },
                  on: { click: _vm.openAddForm },
                },
                [_vm._v("添加")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("a-table", {
        directives: [
          {
            name: "has",
            rawName: "v-has",
            value: "production:chapter:page:view",
            expression: "'production:chapter:page:view'",
          },
        ],
        ref: "table",
        staticStyle: { "border-top": "1px solid rgb(232,232,232)" },
        attrs: {
          size: "middle",
          rowKey: "id",
          columns: _vm.columns.filter(
            (item) =>
              (_vm.isJf &&
                (item.dataIndex == "sequenceNo" ||
                  item.dataIndex == "chapterName")) ||
              !_vm.isJf
          ),
          dataSource: _vm.dataSource,
          customRow: _vm.clickRow,
          pagination: _vm.ipagination,
          scroll: { x: "max-content", y: "calc(100vh - 410px)" },
          loading: _vm.loading,
          rowSelection: _vm.isJf
            ? null
            : {
                selectedRowKeys: _vm.selectedRowKeys,
                onChange: _vm.onSelectChange,
                columnWidth: 80,
              },
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u([
          {
            key: "chapterState",
            fn: function (text, record) {
              return [
                _vm._v("\n      " + _vm._s(record.chapterState) + "\n    "),
              ]
            },
          },
          {
            key: "calcUnits",
            fn: function (text, record) {
              return [
                !_vm.tableEdit
                  ? _c("span", [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.getCalcUnits(
                              record.calcUnits.filter((c) => {
                                return (
                                  _vm.calcUnits.findIndex(
                                    (i) => i.type == c.type
                                  ) > -1 &&
                                  c.value &&
                                  c.value > 0
                                )
                              })
                            )
                          ) +
                          "\n      "
                      ),
                    ])
                  : _vm.tableEdit && record.calcUnits
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                          "align-items": "center",
                        },
                      },
                      [
                        _vm._l(
                          record.calcUnits.filter((c) => {
                            return (
                              _vm.calcUnits.findIndex((i) => i.type == c.type) >
                              -1
                            )
                          }),
                          function (c, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                },
                              },
                              [
                                _c("a-input-number", {
                                  attrs: { min: 0 },
                                  model: {
                                    value: c.value,
                                    callback: function ($$v) {
                                      _vm.$set(c, "value", $$v)
                                    },
                                    expression: "c.value",
                                  },
                                }),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "padding-left": "5px",
                                      "padding-right": "5px",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.getUnit(c.type)))]
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        _vm._l(
                          _vm.calcUnits.filter((c) => {
                            return (
                              record.calcUnits.findIndex(
                                (i) => i.type == c.type
                              ) == -1
                            )
                          }),
                          function (c, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                },
                              },
                              [
                                _c("a-input-number", {
                                  attrs: { min: 0 },
                                  model: {
                                    value: c.value,
                                    callback: function ($$v) {
                                      _vm.$set(c, "value", $$v)
                                    },
                                    expression: "c.value",
                                  },
                                }),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "padding-left": "5px",
                                      "padding-right": "5px",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.getUnit(c.type)))]
                                ),
                              ],
                              1
                            )
                          }
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "sequenceNo",
            fn: function (text, record) {
              return _c(
                "span",
                {},
                [
                  !_vm.tableEdit
                    ? _c("span", [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              record.sequenceNo
                                ? parseFloat((record.sequenceNo - 0).toFixed(1))
                                : ""
                            ) +
                            "\n      "
                        ),
                      ])
                    : _c("a-input-number", {
                        attrs: { min: 0 },
                        model: {
                          value: record.sequenceNo,
                          callback: function ($$v) {
                            _vm.$set(record, "sequenceNo", $$v)
                          },
                          expression: "record.sequenceNo",
                        },
                      }),
                ],
                1
              )
            },
          },
          {
            key: "chapterName",
            fn: function (text, record) {
              return _c(
                "span",
                {},
                [
                  !_vm.tableEdit
                    ? _c("span", [
                        _vm._v(
                          "\n        " +
                            _vm._s(record.chapterName || "") +
                            "\n      "
                        ),
                      ])
                    : _c("a-input", {
                        model: {
                          value: record.chapterName,
                          callback: function ($$v) {
                            _vm.$set(record, "chapterName", $$v)
                          },
                          expression: "record.chapterName",
                        },
                      }),
                ],
                1
              )
            },
          },
          {
            key: "user",
            fn: function (text, record) {
              return [
                record.user
                  ? _c("div", [
                      _c("img", {
                        staticStyle: {
                          width: "30px",
                          height: "30px",
                          "border-radius": "50%",
                          "margin-right": "10px",
                        },
                        attrs: { src: record.user.avatar, alt: "" },
                      }),
                      _vm._v(
                        "\n        " +
                          _vm._s(record.user ? record.user.userName : "") +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "coverImage",
            fn: function (text, record) {
              return [
                record.coverImage
                  ? _c("img", {
                      staticClass: "cover_image",
                      attrs: { src: record.coverImage },
                    })
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("chapter-modal", {
        attrs: {
          calcUnits: _vm.calcUnits,
          isTongChou: _vm.parent.isTongChou,
          open: _vm.drawerShow,
          "cur-row": _vm.curRow,
          isForm: _vm.isNewForm,
        },
        on: { afterSave: _vm.parentLoadData, closed: _vm.drawerClosed },
      }),
      _c("chapter-recycle-bin-modal", {
        attrs: { productionId: _vm.id, visible: _vm.recycleBinVisible },
        on: {
          "update:visible": function ($event) {
            _vm.recycleBinVisible = $event
          },
          ok: _vm.modalFormOk,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }